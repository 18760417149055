@import url("https://fonts.googleapis.com/css?family=Press+Start+2P");
@import url("https://fonts.googleapis.com/css?family=VT323");

.App {
  text-align: center;
  font-family: "Press Start 2P", "VT323";
  color: white;
}

* {
  box-sizing: border-box;
}

body {
  overflow: hidden;
}

.overlay {
  opacity: 1;
  width: 100%;
  height: 100%;
  z-index:10;
  top:0;
  left:0;
  position:fixed;
}

.moving-background{
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom:0;
  background-image: url("./assets/GOB_GAME_1.png");
  /* background-image: url("./assets/ghost_two.gif"); */
  background-size:cover;
  background-attachment: fixed;
  background-position: 40% 40%;
}

